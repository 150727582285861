import React from 'react';
import '../assets/styles/sass/index.scss';
import { Route, Switch } from 'react-router-dom';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Home from '../pages/Home';
import Rooms from '../pages/Rooms';
import SingleRoom from '../pages/SingleRoom';
import ErrorPage from '../pages/ErrorPage';
import Impressum from '../pages/Impressum';
import Datenschutz from '../pages/Datenschutz';
import AGB from '../pages/AGB';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/rooms/" component={Rooms} />
        <Route exact path="/rooms/:slug" component={SingleRoom} />
        <Route exact path="/impressum/" component={Impressum}/>
        <Route exact path="/datenschutz/" component={Datenschutz}/>
        <Route exact path="/agb/" component={AGB}/>
        <Route exact path='/buchung' component={() => { 
          window.location.href = 'https://direct.bookingandmore.com/accommodation/a1b26a89-8045-4af2-aad7-6411b3c31370?lang=de'; 
          return null;
          }}/>
        <Route component={ErrorPage} />
      </Switch>
      <Footer/>
    </React.Fragment>
  );
}

export default App;
