import { Link } from "react-router-dom";
import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <ul className="nav-links">
          {/*<Link to="#">Kontakt</Link>*/}
          <li><Link to="/impressum">Impressum</Link></li>
          <li><Link to="/datenschutz">Datenschutz</Link></li>
          {/*<Link to="/agb">AGB</Link>*/}
        </ul>
      </footer>
    );
  }
}
