import React, { useRef } from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';

const img1 = require('../assets/images/img_001.jpg');
const img2 = require('../assets/images/schlafzimmer-2.JPG');
const img3 = require('../assets/images/img_005.jpg');

const Home: React.FC = (props) => {

    const ferienwohnungRef = useRef(null)
    const scrollToRef = (ref:any) => window.scrollTo(0, ref.current.offsetTop) 
    const executeScroll  = () => scrollToRef(ferienwohnungRef)



    return (
        <React.Fragment>
            <Cover>
                <Banner
                title="Ferienwohnung Pirmann"
                subtitle="Herein spaziert"
                >
                <a onClick={executeScroll} className="btn-primary">Zur Ferienwohnung</a>
                </Banner>
            </Cover>
            {/*<Services/>*/}



            <section className="single-room" id="ferienwohnung-details" ref={ferienwohnungRef}>
                <div className="single-room-images" >
                    <img alt='Ferienwohnung - ' src={String(img1)} />
                    <img alt='Ferienwohnung - ' src={String(img2)} />
                    <img alt='Ferienwohnung - ' src={String(img3)} />
                </div>
                <div className="single-room-info">
                    <article className="desc">
                        <h3>details</h3>
                        <p>Von unseren Gästen hören wir beim Betreten unserer Ferienwohnung eigentlich immer zuerst: „Wahnsinn, so viel Platz und alles da, was wir für einen erholsamen Urlaub alles brauchen!“ Mit ihren 50 qm bietet unser Appartement viel Raum zum Schlafen, Entspannen, Kochen und Genießen. Im separaten Schlafzimmer mit großem Kleiderschrank steht bei Bedarf ein Babybett aus Holz für die kleinsten Urlauber bereit. Das moderne Sofa im großzügigen Wohnzimmer kann im Handumdrehen in ein bequemes Doppelbett umgewandelt werden.
                            In unserer Kochecke können Sie Ihre Familie so richtig verwöhnen: Cerankochfeld mit vier Platten, Backofen, Kaffeemaschine, Toaster, Kühlschrank mit Gefrierfach und eine große Arbeitsfläche.<br/>
                            Wenn Sie nach dem leckeren Mahl in der Essecke noch ein bisschen relaxen möchten, können Sie Ihren Lieblingsfilm auf unserem SAT-TV anschauen.
                        </p>
                    </article>
                    <article className="info">
                        <h3>info</h3>
                        <h6>Preis : 55€</h6>
                        <h6>Gr&ouml;ße : 50 m&sup2;</h6>
                        <h6>Kapazit&auml;t: 4 Personen</h6>
                        <h6>Tiere: Nein</h6>
                    </article>
                </div>
            </section>

            <section className="room-extras">
                <h6>extras</h6>
                <ul className="extras">
                <li>Cerankochfeld</li>
                <li>Backofen</li>
                <li>Kaffeemaschine</li>
                <li>WiFi</li>
                <li>Toaster</li>
                <li>Kühlschrank mit Gefrierfach</li>
                <li>SAT-TV</li>
                <li>Handtücher</li>
                <li>Föhn</li>
            </ul>
            </section>
        
            <section>
                <div className="section-title-booking">
                    <a href="https://direct.bookingandmore.com/accommodation/a1b26a89-8045-4af2-aad7-6411b3c31370?lang=de"><h4 className="btn-primary">Jetzt Buchen</h4></a>
                </div>
            </section>

     </React.Fragment>
    )
}

export default Home
